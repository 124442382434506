/* ========================================================= * Material Kit 2 PRO React - v2.0.0 ========================================================= * Product Page: https://www.creative-tim.com/product/material-kit-pro-react * Copyright 2021 Creative Tim (https://www.creative-tim.com) Coded by www.creative-tim.com ========================================================= * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software. */


// react
import { useMaterialUIController } from "/context";

// next
import dynamic from "next/dynamic";
import Image from "next/image";

// Images
import unilock from "/assets/img/logos/unilock-logo.png";
import techoBloc from "/assets/img/logos/techo-bloc-logo.png";
import cambridge from "/assets/img/logos/cambridge-logo.png";
import nicolock from "/assets/img/logos/nicolock-logo.png";

// MUI
const Container = dynamic(() => import("@mui/material/Container"), { loading: () => null });
const Grid = dynamic(() => import("@mui/material/Grid"), { loading: () => null });

// dynamic PRO
const MDBox = dynamic(() => import('/components/MDBox'), { loading: () => null });

function ALLDBrandsSmallSmall() {
  const [controller] = useMaterialUIController();
  return (
  <Container sx={{ maxWidth: "1000px !important" }}>
    <Grid container spacing={3}>
      <Grid item xs={6} md={6} lg={3}>
        <MDBox p={controller.mobileView ? 0 : 4} opacity={0.7}>
          <Image
            src={cambridge}
            alt="cambridge"
            shadow="md"
            sizes="(max-width: 768px) 80vw, (max-width: 1000px) 40vw, 24vw"
            width={156}
            height="auto"
          />
        </MDBox>
      </Grid>
      <Grid item xs={6} md={6} lg={3}>
        <MDBox p={controller.mobileView ? 0 : 4} opacity={0.7}>
          <Image
            src={nicolock}
            alt="nicolock"
            shadow="md"
            sizes="(max-width: 768px) 80vw, (max-width: 1000px) 40vw, 24vw"
            width={156}
            height="auto"
          />
        </MDBox>
      </Grid>
      <Grid item xs={6} md={6} lg={3}>
        <MDBox p={controller.mobileView ? 0 : 4} opacity={0.7}>
          <Image
            src={techoBloc}
            alt="techoBloc"
            shadow="md"
            sizes="(max-width: 768px) 80vw, (max-width: 1000px) 40vw, 24vw"
            width={156}
            height="auto"
          />
        </MDBox>
      </Grid>
      <Grid item xs={6} md={6} lg={3}>
        <MDBox p={controller.mobileView ? 0 : 4} opacity={0.7}>
          <Image
            src={unilock}
            alt="unilock"
            shadow="md"
            sizes="(max-width: 768px) 80vw, (max-width: 1000px) 40vw, 24vw"
            width={156}
            height="auto"
          />
        </MDBox>
      </Grid>
    </Grid>
  </Container>
  );
}

export default ALLDBrandsSmallSmall;
